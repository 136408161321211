import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RollbarErrorTracking from 'utils/v2/rollbar.js'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorInfo: '',
    }
  }

  componentDidCatch(error) {
    RollbarErrorTracking.logErrorInRollbar(error)
    this.setState({
      hasError: true,
      errorInfo: error.message || '',
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>
          Something went wrong!
          <br />
          {this.state.errorInfo}
        </p>
      )
    }
    return this.props.children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.any,
}

export default ErrorBoundary
