import React from 'react'
import cs from 'classnames'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import { incidentUserType, viewableFileExt } from 'constants/app'
import { SuspectSvgIcn, VictimSvgIcn, WitnessSvgIcn } from 'svgIcon'
import { Tooltip } from 'antd'
export const getStorageData = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key)) || {}
  } catch (err) {
    return {}
  }
}

export const concatAddressValue = (
  address_prefix,
  address_line_1,
  address_line_2,
  address_line_3,
  city,
  ad_state,
  postcode,
  country
) => {
  const addres_value = [
    address_prefix,
    address_line_1,
    address_line_2,
    address_line_3,
    city,
    ad_state,
    postcode,
    country,
  ]
  let result = addres_value.map((item, index) => {
    if (item !== '' && item !== undefined && item !== null) {
      return item.trim().split(/ +/).join(' ')
    }
    return ''
  })
  let final_result = result.filter((item) => item !== '')
  return final_result.join(', ')
}

export const commaAddspace = (data) => {
  const getSpace = data.split(',').join(', ')
  return getSpace
}
export const redirectOpenDocument = (file_type, file_url, file_name) => {
  if (!viewableFileExt.includes(file_type)) {
    const a = document.createElement('a')
    a.download = file_name
    a.href = file_url
    a.click()
  } else {
    var win = window.open()
    win.document.write(
      '<iframe src="' +
        file_url +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    )
  }
}
export const lineBreakText = (text) => {
  const linesBreak = text.split('\n')
  return linesBreak.map((line, index) => {
    if (line === '') {
      return <br key={index} />
    } else {
      return (
        <div className="wordBreak" key={index}>
          {line}
        </div>
      )
    }
  })
}
export const setStorageData = (key, data) => {
  const dataTemp = JSON.stringify(data)
  window.localStorage.setItem(key, dataTemp)
}

export const removeStorageItem = (key) => {
  window.localStorage.removeItem(key)
}

//to check blank
export const isEmpty = (value) =>
  value === undefined || value === null || value === ''

export const dateFormate = (date, timeZone) => {
  let newDate
  if (timeZone) {
    newDate = changeTimeZone(date, timeZone)
  } else {
    newDate = new Date(date)
  }
  const year = newDate.getFullYear()
  const currentDate = newDate.getDate()
  const currentMonth = newDate.toLocaleString('default', { month: 'short' })
  const day = currentDate < 10 ? `0${currentDate}` : currentDate
  const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
  return `${day} ${month} ${year}`
}

export const monthYearFormate = (date, timeZone) => {
  let newDate
  if (timeZone) {
    newDate = changeTimeZone(date, timeZone)
  } else {
    newDate = new Date(date)
  }
  const year = newDate.getFullYear()
  const currentDate = newDate.getDate()
  const currentMonth = newDate.toLocaleString('default', { month: 'short' })
  const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
  return `${month} ${year}`
}

export const dateAndTimeFormate = (date, timeZone) => {
  let newDate
  if (timeZone) {
    newDate = changeTimeZone(date, timeZone)
  } else {
    newDate = new Date(date)
  }
  const year = newDate.getFullYear()
  const currentDate = newDate.getDate()
  const hours = newDate.getHours()
  const currentMinute = newDate.getMinutes()
  const currentMonth = newDate.toLocaleString('default', { month: 'short' })
  const day = currentDate < 10 ? `0${currentDate}` : currentDate
  const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
  const minutes = currentMinute < 10 ? `0${currentMinute}` : currentMinute
  return `${day} ${month} ${year} ${hours}:${minutes}`
}

//falg 0 to add days 1 to Minus days.
export const addMinusDay = (date, days, falg) => {
  var selectedDate = new Date(date)
  falg == 0
    ? selectedDate.setDate(selectedDate.getDate() + days)
    : selectedDate.setDate(selectedDate.getDate() - days)
  return selectedDate
}

export const timeFormate = (date, timeZone) => {
  const today = new Date()
  let newDate = new Date(date)
  if (isNaN(newDate)) {
    date = combineDateAndTime(today, '', '', 'YYYY-M-D') + ' ' + date
  }
  if (timeZone) {
    newDate = changeTimeZone(date, timeZone)
  } else {
    newDate = new Date(date)
  }
  const hours =
    newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
  const minutes =
    newDate.getMinutes() < 10
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes()
  return `${hours}:${minutes}`
}

export const defaultTimeadd = (date, timeZone) => {
  const today = new Date()
  let newDate = new Date(date)
  if (isNaN(newDate)) {
    newDate = new Date(dateFormate(today) + ' ' + date)
  }
  if (timeZone) {
    newDate = changeTimeZone(newDate, timeZone)
  }
  const hours =
    newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
  const minutes =
    newDate.getMinutes() < 10
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes()
  return `${hours}:${minutes}`
}

export const combineDateAndTime = (
  date,
  time,
  timeZone,
  timeFormate,
  combine = true
) => {
  let newDate
  const combineDate = combine ? date + ' ' + time : date

  if (timeZone) {
    newDate = changeTimeZone(combineDate, timeZone)
  } else {
    newDate = new Date(combineDate.trim())
  }
  const year = newDate.getFullYear()
  const currentDate = newDate.getDate()
  const hours = String(newDate.getHours()).padStart(2, '0')
  const minutes = String(newDate.getMinutes()).padStart(2, '0')
  const seconds = String(newDate.getSeconds()).padStart(2, '0')
  let currentMonth = newDate.getMonth() + 1

  const day = currentDate < 10 ? `0${currentDate}` : currentDate
  let month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
  let days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const dayName = days[newDate.getDay()]

  switch (timeFormate) {
    case 'YYYY-M-D H:m':
      return `${year}-${month}-${day} ${hours}:${minutes}`
    case 'YYYY-M-D H:m:s':
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    case 'H:m':
      return `${hours}:${minutes}`
    case 'ddd D M Y':
      currentMonth = newDate.toLocaleString('default', { month: 'short' })
      month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
      return `${dayName.slice(0, 3)} ${day} ${month} ${year}`
    case 'ddd':
      return `${dayName.slice(0, 3)}`
    case 'YYYY-M-D':
      return `${year}-${month}-${day}`
    case 'D M Y H:m':
      currentMonth = newDate.toLocaleString('default', { month: 'short' })
      month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
      return `${day} ${month} ${year} ${hours}:${minutes}`
    case 'D M Y, H:m':
      currentMonth = newDate.toLocaleString('default', { month: 'short' })
      month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
      return `${day} ${month} ${year}, ${hours}:${minutes}`
    case 'D M Y':
      currentMonth = newDate.toLocaleString('default', { month: 'short' })
      month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
      return `${day} ${month} ${year}`
    case 'D M':
      currentMonth = newDate.toLocaleString('default', { month: 'short' })
      month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
      return `${day} ${month}`
    case 'ddd D, Y, H:m':
      currentMonth = newDate.toLocaleString('default', { month: 'short' })
      month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
      return `${dayName.slice(0, 3)} ${day}, ${year}, ${hours}:${minutes}`
    default:
      return `${day} ${month} ${year} ${hours}:${minutes}`
  }
}

export const shortAttchedFile = (document_attachment) => {
  if (document_attachment.length <= 12) {
    return document_attachment
  }
  const firstFour = document_attachment && document_attachment.substring(0, 4)
  const lastFour =
    document_attachment &&
    document_attachment.slice(document_attachment.length - 8)
  const result = firstFour && firstFour + '...' + lastFour
  return result
}

export const short15DigitChar = (name) => {
  if (name.length > 15) {
    const truncatedString = name.substring(0, 15)
    const displayString = truncatedString + '...'
    return <Tooltip title={name}>{displayString}</Tooltip>
  }
  return name
}

const changeTimeZone = (newDate, timeZone) => {
  if (timeZone === 'UTC') {
    newDate = new Date(newDate)
    return new Date(newDate.toLocaleString('en-US', { timeZone: 'UTC' }))
  } else if (timeZone === 'current') {
    newDate = combineDateAndTime(newDate, '', '', 'YYYY-M-D H:m:s')
    newDate = new Date(newDate.trim() + '.000000Z')

    return new Date(
      newDate.toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    )
  }
}

export const currentDate = () => {
  const date = new Date()
  var dd = String(date.getDate()).padStart(2, '0')
  var mm = String(date.getMonth() + 1).padStart(2, '0')
  var yyyy = date.getFullYear()
  return yyyy + '-' + mm + '-' + dd
}

export const addMonthdateFormate = (date, timeZone, addMonth) => {
  let newDate
  if (timeZone) {
    newDate = changeTimeZone(date, timeZone)
  } else {
    newDate = new Date(date)
  }
  const year = newDate.getFullYear()
  const currentDate = newDate.getDate()
  const currentMonth = newDate.toLocaleString('default', { month: 'short' })
  const MonthCurrent = newDate.getMonth()
  const day = currentDate < 10 ? `0${currentDate}` : currentDate
  const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
  const addNewMonth = MonthCurrent + addMonth + 1
  const checkMonth = addNewMonth <= 12 ? addNewMonth : addNewMonth - 12
  const newMonth = checkMonth < 10 ? `0${checkMonth}` : checkMonth
  const newYear = addNewMonth <= 12 ? year : year + 1
  return `${newMonth} ${day} ${newYear}`
}

export const overrideClassHandler = (
  className,
  componentClasses,
  parentClassNames
) => {
  return cs({
    [parentClassNames[className]]: parentClassNames[className],
    [componentClasses[className]]: !parentClassNames[className],
  })
}
export const clearStorageData = (key) => window.localStorage.removeItem(key)

export const getSessionData = (key) => {
  return JSON.parse(window.sessionStorage.getItem(key)) || {}
}

export const setSessionData = (key, data) => {
  const dataTemp = JSON.stringify(data)
  window.sessionStorage.setItem(key, dataTemp)
}

export const clearSessionData = (key) => window.sessionStorage.removeItem(key)

export const checkHttpOrNot = (value) => {
  try {
    new URL(value)
    return value
  } catch (err) {
    return `https://${value}`
  }
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const concatFileName = (fileName) => {
  fileName = fileName ? fileName.replace('/s3', '') : ''
  let fileNameArray = fileName.split('/')
  if (fileName.length <= 12) {
    return fileName
  }
  fileName = fileNameArray[fileNameArray.length - 1]
  return fileName.slice(0, 4) + '...' + fileName.slice(-8)
}

export const concatOmName = (omData) => {
  let omFullName = ''
  if (omData) {
    omFullName = omData.name + ' ' + omData.surname
  }
  return omFullName
}

export const fullFileName = (fileName) => {
  fileName = fileName.replace('/s3', '')
  let fileNameArray = fileName.split('/')
  return fileNameArray[fileNameArray.length - 1]
}

export const addMinuteInTime = (time, addMinutes) => {
  const newDate = new Date(new Date(time).getTime() + addMinutes * 60000)
  const hours =
    newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
  const minutes =
    newDate.getMinutes() < 10
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes()
  return `${hours}:${minutes}`
}

export const addMinuteInEndtime = (start_time) => {
  return moment(start_time, 'HH:mm').add(15, 'minutes').format('HH:mm')
}

export const ReCAPTCHAKey = process.env.REACT_APP_RECAPTCHA

export const calculateAge = (dob1) => {
  var today = new Date()
  var birthDate = new Date(dob1)
  var age_now = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--
  }
  return age_now
}

export const addressPrefixConcat = (address) => {
  let address_prefix = `${addressFormat(address.establishment)} ${addressFormat(
    address.landmark
  )} ${addressFormat(address.park)} ${addressFormat(
    address.point_of_interest
  )} ${addressFormat(address.tourist_attraction)} ${addressFormat(
    address.intersection
  )} ${addressFormat(address.premise)} ${addressFormat(
    address.subpremise
  )} ${addressFormat(address.natural_feature)} ${addressFormat(
    address.airport
  )} ${addressFormat(address.floor)} ${addressFormat(
    address.parking
  )} ${addressFormat(address.post_box)}`

  return address_prefix !== '' &&
    address_prefix !== undefined &&
    address_prefix !== null
    ? address_prefix.trim().split(/ +/).join(' ')
    : ''
}

export const addressLine1Concat = (address) => {
  let address_line_1 = `${addressFormat(
    address.street_number
  )}  ${addressFormat(address.street_address)} ${addressFormat(address.route)}`

  return address_line_1 !== '' &&
    address_line_1 !== undefined &&
    address_line_1 !== null
    ? address_line_1.trim().split(/ +/).join(' ')
    : ''
}

export const addressLine2Concat = (address) => {
  let address_line_2 = `${addressFormat(address.neighborhood)}`
  return address_line_2 !== '' &&
    address_line_2 !== undefined &&
    address_line_2 !== null
    ? address_line_2.trim().split(/ +/).join(' ')
    : ''
}

export const addressLine3Concat = (address) => {
  let address_line_3 = `${addressFormat(
    address.administrative_area_level_4
  )} ${addressFormat(address.administrative_area_level_5)} ${addressFormat(
    address.administrative_area_level_6
  )} ${addressFormat(address.administrative_area_level_7)} ${addressFormat(
    address.colloquial_area
  )} ${addressFormat(address.sublocality)} ${addressFormat(
    address.sublocality_level_1
  )} ${addressFormat(address.sublocality_level_2)} ${addressFormat(
    address.sublocality_level_3
  )} ${addressFormat(address.sublocality_level_4)} ${addressFormat(
    address.sublocality_level_5
  )}`
  return address_line_3 !== '' &&
    address_line_3 !== undefined &&
    address_line_3 !== null
    ? address_line_3.trim().split(/ +/).join(' ')
    : ''
}

export const addressPostCode = (address) => {
  let postal_code = `${addressFormat(address.postal_code)}`
  return postal_code !== '' && postal_code !== undefined && postal_code !== null
    ? postal_code.trim().split(/ +/).join(' ')
    : ''
}

export const addressCity = (address) => {
  let city = `${addressFormat(address.postal_town)} ${addressFormat(
    address.locality
  )}`
  return city !== '' && city !== undefined && city !== null
    ? city.trim().split(/ +/).join(' ')
    : ''
}

export const addressState = (address) => {
  let state = `${addressFormat(
    address.administrative_area_level_2
  )} ${addressFormat(address.administrative_area_level_3)}`
  return state !== '' && state !== undefined && state !== null
    ? state.trim().split(/ +/).join(' ')
    : ''
}

export const addressCountry = (address) => {
  let country = address.country
    ? `${addressFormat(address.country)}`
    : `${addressFormat(address.administrative_area_level_1)}`

  return country !== '' && country !== undefined && country !== null
    ? country.trim().split(/ +/).join(' ')
    : ''
}

const addressFormat = (component) => {
  return component !== undefined && component !== null ? component : ''
}

export const replaceMultiString = (string, replaceArray) => {
  let newString = string
  replaceArray.map(({ newValue, oldValue }) => {
    newString = newString.replace(oldValue, newValue)
  })
  return newString
}

export const addRemainAttr = (selector = '', title) => {
  // Add Title
  const buttons = document.querySelectorAll(selector)
  // Iterate through each button-like element and add title if not present
  buttons.forEach((button) => {
    if (!button.hasAttribute('title') || button.getAttribute('title') === '') {
      button.setAttribute('title', title)
    }
    if (
      !button.hasAttribute('aria-label') ||
      button.getAttribute('aria-label') === ''
    ) {
      button.setAttribute('aria-label', title)
    }
    if (
      !button.hasAttribute('aria-labelledby') ||
      button.getAttribute('aria-labelledby') === ''
    ) {
      button.setAttribute('aria-labelledby', title)
    }
  })
}

export const getOOCDUserIcon = (key, portalVariable) => {
  switch (key) {
    case incidentUserType.SUSPECT:
      return (
        <Tooltip
          title={portalVariable.suspect ? portalVariable.suspect : 'Suspect'}
        >
          <>
            <SuspectSvgIcn />
          </>
        </Tooltip>
      )
    case incidentUserType.VICTIM:
      return (
        <Tooltip title="Victim">
          <>
            <VictimSvgIcn />
          </>
        </Tooltip>
      )
    case incidentUserType.WITNESS:
      return (
        <Tooltip title="Witness">
          <>
            <WitnessSvgIcn />
          </>
        </Tooltip>
      )
  }
}
export const getTimeDifference = (date) => {
  const currentDate = new Date()
  const providedDate = new Date(
    combineDateAndTime(date, '', 'current', 'YYYY-M-D')
  )

  const yearDiff = currentDate.getFullYear() - providedDate.getFullYear()
  const monthDiff = currentDate.getMonth() - providedDate.getMonth()
  const dayDiff = currentDate.getDate() - providedDate.getDate()

  if (yearDiff > 0) {
    return `${yearDiff} ${yearDiff === 1 ? 'year' : 'years'} ago`
  } else if (monthDiff > 0) {
    return `${monthDiff} ${monthDiff === 1 ? 'month' : 'months'} ago`
  } else if (dayDiff > 0) {
    return `${dayDiff} ${dayDiff === 1 ? 'day' : 'days'} ago`
  } else {
    return 'today'
  }
}

// customTabs select

export const isKeyIDPresent = (array, keyID) =>
  array.some((item) => item.keyID === keyID)

export const mergeArrays = (mainArray, customArray) => {
  const newArray = []

  mainArray.forEach((option) => {
    newArray.push(option)

    // Check if customArray is defined and not empty
    if (customArray && customArray.length > 0) {
      // Check if there is a matching customTab entry for the current option
      const matchingCustomTabs = customArray.filter((customTab) => {
        if (customTab.is_custom) {
          // Match based on keyID if is_custom is true
          const matchingCustomToCustom = newArray.filter((newkey) => {
            if (newkey.value && customTab.value !== newkey.value) {
              return customTab.afterTab === newkey.value
            }
          })
          if (matchingCustomToCustom.length > 0) {
            const isPresent = isKeyIDPresent(newArray, customTab.value)
            !isPresent && newArray.push(customTab)
            //newArray.push(customTab)
          }
        } else {
          // Match based on afterTab if is_custom is not true
          return customTab.afterTab === option.assignId
        }
      })
      // If matching customTabs are found, add them to the newArray
      if (matchingCustomTabs.length > 0) {
        newArray.push(...matchingCustomTabs)
      }
    }
  })

  return newArray
}

export const orgSettingPermission = (
  userPermissions,
  module_name,
  userType
) => {
  var module_access = userPermissions.filter(
    (item, i) => item.permission_id === module_name
  )

  return (module_access.length && module_access[0].read_status >= 1) ||
    (userType && userType.toString() === '1')
    ? true
    : false
}
