import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import monoDarkSvg from 'assets/images/monoDark.svg'
import classNames from 'classnames'
import { withTheme } from 'react-jss'
import { useStyles } from './style'

const LoadingIconComponent = ({
  heyLoader,
  theme,
  extraClass,
  style,
  smallLoader,
}) => {
  const classes = useStyles(theme)
  return (
    <div
      className={classNames(
        heyLoader && classes.heyLoader,
        extraClass,
        smallLoader && classes.smallLoader,
        'heyLoaderCommon'
      )}
      style={style}
    >
      {heyLoader ? (
        <div className="hlCricle">
          <img width={32} height={32} alt="MTC Monogram" src={monoDarkSvg} />
          <span></span>
        </div>
      ) : (
        <LoadingOutlined />
      )}
    </div>
  )
}
export default withTheme(LoadingIconComponent)
